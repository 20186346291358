import '../styles/Coupons.css';
import Logo from "../assets/read_infinite_banner.png";
import Footer from "./Footer";
import TopMenu from "./TopMenu";
import {useEffect, useState} from "react";
import {getContent, getCoupons} from "../api/api";


const CouponsPage = () => {

    const [content, setContent] = useState([]);
    const [coupons, setCoupons] = useState({});


    useEffect(()=>{
        (async ()=>{
            const data = await getContent();
            setContent(data);
            const couponsData = await getCoupons();
            setCoupons(couponsData);
        })();
    },[]);

    const BigDeals = ({bottom, imageSource, title,content,links,button}) =>
        <div className="bigdeals-container">
            <div className='bigdeals-image-container'>
                <img className='deals-image' src={imageSource}/>
            </div>
            <div className='bigdeals-content'>
                <h2><a href=''>{title}</a></h2>
                <div>
                    <span>
                        {content}
                    </span>
                </div>
            </div>
            <div className='bigdeals-links'>
                {links.map((link) => {
                    return <a key={link} className='bigdeals-link' href=''>{link}</a>
                })}
            </div>
            <div className='bigdeals-button-container'>
                <button className='bigdeals-button'>{button}</button>
            </div>
        </div>

    const SmallDeals = ({imageSource, title,content}) =>
        <div className='smalldeals-container'>
            <div className='smalldeals-image-container'>
                <img className='deals-image' src={imageSource}/>
            </div>
            <div className='smalldeals-content'>
                <div className='smalldeals-content-title'>
                    <span>{title}</span>
                </div>
                <div className='smalldeals-content-desc'>
                    {content}
                </div>
            </div>
        </div>

    const CouponsContent = () =>
        <div>
            <TopMenu back_image={Logo} content={content}/>
            <div className="coupons-container">
                <span className='small-title'>Sponsored Links</span>
                {Object.keys(coupons.bigDeals).map((key) => {
                    return <BigDeals key={key} imageSource={coupons.bigDeals[key].imageSource} title={coupons.bigDeals[key].title} content={coupons.bigDeals[key].content} links={coupons.bigDeals[key].links} button={coupons.bigDeals[key].button}/>
                })}
            </div>
            <div className='coupons-container'>
                <span className='smalldeals-header'>Save Up To 50% On Hello Fresh Deals</span>
                {Object.keys(coupons.smallDeal).map((key) => {
                    return <SmallDeals key={key} imageSource={coupons.smallDeal[key].imageSource} title={coupons.smallDeal[key].title} content={coupons.smallDeal[key].content} />
                })}
            </div>
            <div className="coupons-container">
                <span className='small-title'>Sponsored Links</span>
                {Object.keys(coupons.bigDeals).map((key) => {
                    return <BigDeals key={key} imageSource={coupons.bigDeals[key].imageSource} title={coupons.bigDeals[key].title} content={coupons.bigDeals[key].content} links={coupons.bigDeals[key].links} button={coupons.bigDeals[key].button}/>
                })}
            </div>
            <Footer/>
        </div>

    return  Object.keys(coupons).length > 0 ? <CouponsContent/> : <span>Loading</span>;
}

export default CouponsPage